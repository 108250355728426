@import "../main/abstracts/index";

.content--content-navigation,
.section--content-navigation{

    body[data-template*="home"] &{

        .page__main > .section header {
            margin-bottom: 2.5rem;
          }
    }

    .content--heading + & > .row,
    .section--heading + .row{
        margin-top: 20px;
    }

    .content-navigation{
        --bs-gutter-y: 20px;

        > *{
            margin-top: 0;
            margin-bottom: var(--bs-gutter-y);
        }

        @include media-breakpoint-down(lg) {
            --bs-gutter-y: 10px;
            --bs-gutter-x: 16px;
        }

        &__item{
            min-height: 150px;
            background-color: $color__secondary;

            border: none;
            border-radius: 0;
            padding: 25px 28px 35px 28px;
            transition: all .25s ease;

            @include media-breakpoint-down(lg) {
                min-height: 125px;
            }

            @include media-breakpoint-down(sm) {
                min-height: 100px;
            }

            @media (max-width: 450px) {
                min-height: unset;
            }

            .page__main section.section--bg-dark &{
                background-color: $color__white;

                h3{
                    color: $color__secondary!important;
                }

                a{
                    color: $color__secondary!important;
                }

                &--has-link{

                    @include hover-focus-within{
                        background-color: $color__primary;

                        a{
                            color: $color__white!important;
                        }
                    }
                }
            }

            &--has-link{

                @include hover-focus-within{
                    background-color: $color__primary;

                    .content-navigation__arrow{
                        right: 0;
                    }
                }
            }

            h3{
                color: $color__white;
                line-height: normal;
                font-weight: $font__weight--normal;
            }

            a{
                color: $color__white;
                font-weight: $font__weight--normal;
            }

            &--yellow{
                background-color: $color__tertiary!important;

                &.content-navigation__item--has-link{
                    background-color: $color__tertiary;

                    @include hover-focus-within{
                        background-color: $color__primary!important;

                        a{
                            color: $color__white!important;

                            .page__main section.section--bg-dark &{
                                color: $color__white!important;
                            }
                        }
                    }
                }

                h3{
                    color: $color__secondary!important;
                }

                a{
                    color: $color__secondary!important;
                }
            }
        }

        &__arrow{
            position: absolute !important;
            right: 20px;
            bottom: -25px;

            height: 50px;
            width: 50px;

            border: 13px solid $color__primary;
            color: $color__white;
            background-color: $color__primary;

            margin: 0;
            box-shadow: 0 2px 8px rgba(0,0,0,.4);
            transition: all .25s ease-in-out;

            img{
                filter: $filter__white;
            }

            @include media-breakpoint-down(lg) {
                bottom: -20px;
                height: 40px;
                width: 40px;
                border-width: 8px;
            }
        }
    }
}
